<template>
  <div class="combobox" :class="{ 'form-group--error': validator && validator.$error }" ref="comboboxmultiple">
    <label class="combobox__label" v-if="label"> {{ label }}</label>
    <div class="combobox__select" :style="{
                  '--min-width'     : minWidth,
                  '--max-height'    : maxHeight,
                  '--bg-selected'   : bgSelected,
                  '--bg-options'    : bgOptions,
                  '--color-selected': colorSelected,
                  '--color-options' : colorOptions
                }">
      <div class="combobox__selected" @click="deployed = !deployed"
           :class="{'active': deployed && disabled === false, 'disabled': disabled}">
        <input type="text" v-model="input" :placeholder="setText()" :disabled="disabled"
               :class="{'disabled': disabled}"/>
        <i class="ez-icon-caret-down"/>
      </div>
      <div v-if="deployed && disabled === false" class="combobox__options ez-slim-scroll active">
        <ul class="combobox__list" v-if="options && options.length > 0">
          <li @click="selectAll" v-if="multiple && !input">
            <span v-if="this.selects.length !== this.options.length">{{ $t('select_all') }}</span>
            <span v-else>{{ $t('unselect_all') }}</span>
          </li>
          <li @click="multiple ? selectElement(option) : updateSelect(option)"
              :class="{'selected': multiple ? isSelected(option): null}"
              :value='valueKey ? option[valueKey] : option' v-for="option in changeOptions"
              :key="valueKey ? option[valueKey] : option"
              v-html="option['filter'] ? option['filter'] : valueKey ? option[valueKey] : option">
          </li>
        </ul>
        <span class="no-element" v-else>{{ $t('no_element') }}</span>
      </div>
      <span v-if="error" class="has-error animated headShake">{{ $t(error) }}</span>
      <div v-if="validator && validator.$error">
        <span class="has-error animated headShake" v-if="!validator.required">{{ $t('field required') }}</span>
      </div>
      <span class="combobox__description" v-if="description">{{ description }}</span>
    </div>
    <div class="sources-selected" v-if="selects.length && showSelected">
      <span v-for="source in selects" :key="valueKey ? source[valueKey] : source">{{ displayLabel(source) }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: "ChosenMultiple",
  props: {
    text: {type: String, default: 'N/A'},
    description: {type: String, default: null},
    options: {type: Array},
    valueKey: {type: String, default: null},
    valueText: {type: String, default: null},
    change: {type: Function},
    remove: {type: Function},
    value: null,
    validator: null,
    label: {type: String},
    error: {type: String},
    bgSelected: {type: String, default: '#fff'},
    bgOptions: {type: String, default: '#fff'},
    colorSelected: {type: String, default: '#8798AD'},
    colorOptions: {type: String, default: '#8798AD'},
    border: {type: String},
    minWidth: {type: String, default: '100%'},
    maxHeight: {type: String, default: '200px'},
    showSelected: {type: Boolean, default: true},
    disabled: {type: Boolean, default: false},
    multiple: {type: Boolean, default: true},
    changeSelected: {type: Boolean, default: true}
  },
  data() {
    return {
      deployed: false,
      option: null,
      selects: [],
      input: '',
      changeOptions: []
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!newVal.length)
          this.selects = [];
        if (newVal !== oldVal && !oldVal.length) {
          if (newVal.length && !oldVal.length) {
            let difference = newVal.filter(x => !oldVal.includes(x));
            for (const ele of difference) {
              for (const op of this.options) {
                if (op[this.valueKey].toLowerCase() === ele.toLowerCase()) {
                  this.selectElementNoAct(op);
                }
              }
            }
          } else {
            this.selects = [];
          }
        }
      }
    },

    input(newVal) {
      if (newVal.length) {
        const regExp = new RegExp(newVal, "gi");

        this.changeOptions = this.options
            .filter(el => regExp.test(el[this.valueKey]))
            .map(el => {
              el['filter'] = el[this.valueKey].replace(regExp, matchedText => {
                return `<span style="font-weight: bold"> ${matchedText} </span>`;
              });
              return el;
            })
      } else {
        this.changeOptions = this.options.map(el => {
          delete el.filter;
          return el;
        });
      }
    },

    changeSelected() {
      if (this.changeSelected) {
        this.$emit('new_selected');
        this.selects = [];
      }
    }
  },
  created() {
    document.addEventListener("click", this.documentClick);
  },
  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },
  mounted() {
    this.changeOptions = JSON.parse(JSON.stringify(this.options));

    if (this.value) {
      this.selects = this.value;

      if (!this.multiple) {
        if (this.valueKey) {
          this.option = this.options.find(o => o[this.valueKey].toString().toLowerCase() === this.value.toString().toLowerCase()) || null;
        } else
          this.option = this.options.find(o => o === this.value) || null;

        if (this.option === null || this.option === undefined) {
          this.option = this.value.toString()
        }
      }
    }
  },
  methods: {
    documentClick(e) {
      const el = this.$refs.comboboxmultiple;
      const target = e.target;

      if (el !== target && el && !el.contains(target)) {
        this.deployed = false;
      }
    },

    selectAll() {
      const isAllSelected = this.selects.length === this.options.length;
      if (isAllSelected) {
        this.selects = [];
      } else {
        this.selects = this.options.map(opt => opt[this.valueKey]);
        this.deployed = false;
      }

      this.$emit('input', this.selects);
      if (this.change) this.change(this.selects);
    },

    selectElement(option) {
      if (this.selects.findIndex(ele => ele.toLowerCase() === option[this.valueKey].toLowerCase()) === -1) {
        this.selects.push(option[this.valueKey]);
      } else {
        this.selects.splice(
            this.selects.findIndex(ele => typeof ele === 'string' ? ele.toLowerCase() === option[this.valueKey].toLowerCase() : ele[this.valueKey].toLowerCase() === option[this.valueKey].toLowerCase()
            ), 1);
      }

      this.$emit('input', this.selects);
      if (this.change) this.change(this.selects);
    },

    selectElementNoAct(option) {
      if (this.selects.findIndex(ele => ele.toLowerCase() === option[this.valueKey].toLowerCase()) === -1) {
        this.selects.push(option[this.valueKey]);
      } else {
        this.selects.splice(
            this.selects.findIndex(ele => typeof ele === 'string' ? ele.toLowerCase() === option[this.valueKey].toLowerCase() : ele[this.valueKey].toLowerCase() === option[this.valueKey].toLowerCase()
            ), 1);
      }
    },

    displayLabel(value) {
      for (const s of this.options) {
        if (s[this.valueKey] === value) {
          return s[this.valueText]
        }
      }
    },

    updateSelect(option) {
      this.option = option;
      this.input = this.valueKey ? this.option[this.valueKey] : this.option;
      this.$emit('input', this.valueKey ? this.option[this.valueKey] : this.option);
      this.$emit('change', this.valueKey ? this.option[this.valueKey] : this.option);
      if (this.change) this.change(this.valueKey ? this.option[this.valueKey] : this.option);
      this.deployed = false;
    },

    isSelected(option) {
      const index = this.selects.findIndex(ele => ele === option[this.valueKey]);
      return index > -1;
    },

    setText() {
      if (this.valueText && this.option && typeof this.option !== 'string')
        return this.option[this.valueText]
      else {
        const index = this.changeOptions.findIndex(ele => ele.value === this.option);
        return index >= 0 ? this.option : '';
      }
    }
  }
}
</script>

<style scoped lang="scss">
.combobox {
  --bg: white;
  --border: 1px;
  position: relative;

  &__select {
    border-radius: 6px;
    min-width: var(--min-width);
    display: inline-flex;
    flex-direction: column;
    position: relative;
  }

  &__selected {
    background: var(--bg-selected);
    border: 1px solid color(border);
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 35px;
    justify-content: space-between;

    span {
      color: var(--color-selected);
      font-weight: $font-regular;
      padding: 0 10px;
      font-size: .8rem;
    }

    i {
      color: color(bg-black3);
      border-radius: 6px;
      height: 25px;
      width: 25px;
      margin-right: 10px;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      border-radius: 6px 6px 0 0;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: #E7E8F2;

      input {
        &.disabled {
          cursor: not-allowed;
          background-color: #E7E8F2;
        }
      }
    }
  }

  &__options {
    background: var(--bg);
    border: 1px solid color(border);
    border-radius: 0 0 6px 6px;
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    max-height: var(--max-height);
    top: 35px;
    overflow: auto;
    z-index: 999;

    &.active {
      border-top: 0;
      display: flex;
    }

    .no-element {
      height: 25px;
      padding: 0 10px;
      font-size: .8rem;
      width: 100%;
      margin-top: 10px;
    }
  }

  &__list {
    color: var(--color-options);
    width: 100%;
    height: 100%;

    li {
      display: flex;
      align-items: center;
      height: 30px;
      padding: 0 10px;
      font-size: .8rem;
      width: 100%;

      &.selected {
        position: relative;

        &:after {
          content: '\e9cb';
          font-family: 'ezleadicon';
          position: absolute;
          right: 5px;
          color: color(bg-primary);
          font-size: 1rem;
        }
      }

      &:hover {
        background: color(bg-gray3);
        cursor: pointer;
      }
    }
  }

  &__description {
    color: var(--color-selected);
    font-weight: 400;
    padding: 0 3px;
    font-size: .8rem;
    margin-top: 3px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    color: color(bg-black3);
    font-size: .7rem;
    font-weight: $font-medium;
  }

  select {
    background: var(--bg);
    border: var(--border) solid color(border);
    border-radius: .25rem;
    color: #495057;
    height: 40px;
    font-size: .8rem;
    padding: .3rem .5rem;
    transition: .15s ease-in-out;
    width: 100%;

    &:focus {
      box-shadow: 0 3px 12px rgba(color(bg-gray2), .3);
    }
  }

  .has-error {
    position: absolute;
    bottom: -14px;
    font-size: .7rem;
    right: 5px;
    color: color(bg-red);
    font-weight: $font-medium;
  }

  .bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }

  .sources-selected {
    margin-top: .75rem;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    span {
      text-align: center;
      background: color(bg-primary-light);
      color: color(bg-primary);
      padding: 3px 12px;
      border-radius: 20px;
      font-size: .7rem;
      font-weight: $font-medium;
    }
  }

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    40%,
    43% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -30px, 0);
      transform: translate3d(0, -30px, 0);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translate3d(0, -15px, 0);
      transform: translate3d(0, -15px, 0);
    }

    90% {
      -webkit-transform: translate3d(0, -4px, 0);
      transform: translate3d(0, -4px, 0);
    }
  }

  input {
    --font-color: #495057;
    --placeholder-color: #8798AD;

    background: var(--bg);
    border: var(--border) solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--font-color);
    height: var(--min-height);
    font-size: var(--font-size);
    padding-left: 10px;
    transition: .15s ease-in-out;
    width: var(--max-width);

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--placeholder-color);
      font-size: var(--placeholder-fsize);
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--placeholder-color);
      font-size: var(--placeholder-fsize);
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--placeholder-color);
      font-size: var(--placeholder-fsize);
    }

    &:focus {
      box-shadow: var(--box-shadow) rgba(color(bg-gray2), .3);
    }

    &[type="date"], &[type="datetime-local"] {

      &::-webkit-inner-spin-button {
        opacity: 0
      }
    }

    &[type="date"], &[type="datetime-local"] {

      &::-webkit-calendar-picker-indicator {
        color: var(--font-color);
        font-size: var(--font-size);
        opacity: 0.5
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "select_all": "Select All",
    "unselect_all": "Unselect All",
    "must_select": "Must select a value",
    "no_element": "No element match"
  },
  "es": {
    "select_all": "Marcar Todos",
    "unselect_all": "Desmarcar Todos",
    "must_select": "Debe seleccionar un valor",
    "no_element": "No hay elementos"
  }
}
</i18n>